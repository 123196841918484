<template>
  <UiIconBase>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 8H6V6H18V8Z"
      fill="#17171B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 13H6V11H18V13Z"
      fill="#17171B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 18H6V16H18V18Z"
      fill="#17171B"
    />
  </UiIconBase>
</template>
