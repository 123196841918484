import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationInfoStore = defineStore('notificationInfoStore', () => {
  const notificationList = ref([])

  const setNotificationList = (data) => {
    notificationList.value = data
  }

  return {
    notificationList,
    setNotificationList
  }
})
