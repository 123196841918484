import { executeApi, useApi } from '../../composables/useApi'
import { storeToRefs } from 'pinia'
import { useNotificationInfoStore } from '../../stores/notificationInfoStore'
import dayjs from 'dayjs'
import { navigateTo } from 'nuxt/app'
import { computed } from 'vue'

export default () => {
  const api = useApi()
  const { notificationList } = storeToRefs(useNotificationInfoStore())
  const { setNotificationList } = useNotificationInfoStore()

  const fetchNotificationList = () => {
    executeApi(async () => {
      const { data } = await api.memberData.list()
      setNotificationList(data.map(noti => ({
        ...noti,
        opened: false,
      })))
    })
  }

  const handleMarkRead = async (notificationId) => {
    await executeApi(async () => {
      await api.memberData.readNotification({
        notificationId
      })
    })
    fetchNotificationList()
  }

  const handleMarkUnread = async (notificationId) => {
    await executeApi(async () => {
      await api.memberData.unReadNotification({
        notificationId
      })
    })
    fetchNotificationList()
  }

  const handleMarkDelete = async (notificationId) => {
    await executeApi(async () => {
      await api.memberData.deleteNotification({
        notificationId
      })
    })
    fetchNotificationList()
  }

  const handleToggleButton = (index) => {
    console.log('index', index)
    notificationList.value[index].opened = !notificationList.value[index].opened
  }

  const handleGo = async (noti) => {
    console.log('handleGo', noti)
    const { notificationId, url, messageType } = noti
    console.log('me', messageType, messageType.startsWith('ECA'))
    handleMarkRead(notificationId)
    if (url) {
      if (messageType.startsWith('ECA')) {
        sessionStorage.setItem("AccessActivityPage", 'true')
      }
      navigateTo(url)
    }
  }

  const existUnread = computed(() => {
    return !!notificationList.value.find(noti => noti.read === false)
  })

  const convertDateFormat = (date) => {
    return dayjs(date).format("MMM DD[,] YYYY [at] hh:mm A");
  }

  return {
    notificationList,
    fetchNotificationList,
    handleToggleButton,
    handleMarkRead,
    handleMarkUnread,
    handleMarkDelete,
    handleGo,
    convertDateFormat,
    existUnread,
  }
}
