<script setup>
import { useRoute, useRouter } from 'vue-router'

import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import useSubscriptionService from 'share/services/payment/useSubscriptionService'
const emit = defineEmits(['cancel', 'close', 'confirm', 'trigger'])

const { logout } = useMemberSessionStore()
const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())

const { myPlanString, myPlanButtonString, subscriptionInfo } = useSubscriptionService()

const props = defineProps({
  modelwidth: {
    type: String,
    default: '480px',
  },
})

const route = useRoute() // to get access to the current route

const daysAway = (endDay) => {
  const currentDate = new Date()
  const endDate = new Date(endDay)
  return Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
}

const closeModal = () => {
  emit('closed')
}

const signout = () => {
  logout()
  closeModal()
}

const upgrade = () => {
  if (route.path === '/purchase/subscription') {
    closeModal()
  } else {
    navigateTo('/purchase/subscription')
  }
}
</script>

<template>
  <LayoutPopup fix-bottom is-scroll :modelwidth="modelwidth">
    <div class="full-height-top w-100-p">
      <div
        class="flex w-100-p justify-start m-bottom-16"
        style="gap: 12px"
        :style="{ minWidth: modelwidth ? modelwidth : '480px' }"
      >
        <div class="w-44 h-44 radius-200">
          <img
            :src="memberInfo.imageUrl ? memberInfo.imageUrl : '/images/avatar.svg'"
            alt=""
            class="fit-cover w-100-p h-100-p radius-200"
          />
        </div>
        <div>
          <div class="text-body2-b">{{ memberInfo.name }}</div>
          <div class="text-caption2 text-gray5">{{ memberInfo.memberType }} / {{ memberInfo.email }}</div>
        </div>

        <button class="header-right-icon" @click="closeModal" style="margin-left: auto">
          <IconClose width="24" height="24" color="#747474" view-box="-4 -4 32 32" />
        </button>
      </div>
      <div class="flex w-100-p justify-between items-center m-bottom-12">
        <div>
          <p class="text-caption2 m-bottom-4" style="color: rgba(17, 17, 17, 0.5)">My Plan</p>
          <p class="text-body1-b">
            {{ myPlanString }}
            <span class="text-caption1 text-primary4" v-if="subscriptionInfo.status === 'trialing'">
              {{ daysAway(subscriptionInfo.endDay) }} days left
            </span>
          </p>
        </div>
        <div class="" v-if="['trialing', 'active'].includes(subscriptionInfo.status) === false">
          <UiButton class="w-100-p small" style="white-space: pre" @click="upgrade">Upgrade</UiButton>
        </div>
      </div>

      <div class="flex justify-between w-100-p m-bottom-4">
        <p class="text-caption1 text-gray5">Free Viewing Passes</p>
        <p class="text-caption1-b text-gray6">
          {{ subscriptionInfo.freeTotalPassCount - subscriptionInfo.freeUsedPassCount }}
          / {{ subscriptionInfo.freeTotalPassCount }}
        </p>
      </div>
      <div class="flex justify-between w-100-p m-bottom-24">
        <p class="text-caption1 text-gray5">Purchased Viewing Passes</p>
        <p class="text-caption1-b text-gray6">
          {{ subscriptionInfo.purchasedTotalPassCount - subscriptionInfo.purchasedUsedPassCount }}
          / {{ subscriptionInfo.purchasedTotalPassCount }}
        </p>
      </div>
      <div class="flex justify-end">
        <div>
          <UiTextButton class="w-100-p small" style="white-space: pre" @click="navigateTo('/purchase/subscription')">
            Viewing Pass Recharge
          </UiTextButton>
        </div>
      </div>
      <div class="w-100-p m-y-16" style="border-bottom: 1px solid rgba(17, 17, 17, 0.05)"></div>
      <div class="p-y-8">
        <button
          class="reset-button w-24 h-24 flex items-center"
          @click="navigateTo('/mypond/settings')"
          style="gap: 8px"
        >
          <IconSetting width="24" height="24" color="#747474" />
          <p class="text-body2">Settings</p>
        </button>
      </div>
      <div class="p-y-8">
        <button class="reset-button text-body2 text-gray6 flex items-center" @click="signout">
          <IconSignOut width="24" height="24" class="m-right-8" color="#000" />
          Sign Out
        </button>
      </div>
    </div>
  </LayoutPopup>
</template>

<style scoped lang="scss"></style>
