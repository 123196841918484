<script setup>
import useNotificationService from 'share/services/notification/useNotificationService'

const emit = defineEmits(['cancel'])

const closeModal = () => {
  emit('cancel')
}

useCustomBackButton(closeModal)

const {
  notificationList,
  fetchNotificationList,
  handleToggleButton,
  handleMarkRead,
  handleMarkUnread,
  handleMarkDelete,
  handleGo,
  convertDateFormat
} = useNotificationService()

const handlePcGo = (noti) => {
  handleGo(noti)
  closeModal()
}

onMounted(() => {
  fetchNotificationList()
})
</script>

<template>
  <LayoutModal
    :hide-overlay="true"
    :click-to-close="true"
    modal-id="ModalNotification"
    content-transition="vfm-slide-right"
    is-scroll
    max-width="588"
    max-height="100-vh"
    header-type="empty"
    :closeConfirm="false"
    class="item-align--right radius-0"
  >
    <template v-slot:title>
      <h1 class="text-title2-b modal-title text-black flex items-center p-a-40 letter-spacing-2">
        <button class="w-24 h-24 m-right-12" @click="closeModal">
          <IconClose width="24" height="24" color="#000"/>
        </button>
        Notifications
      </h1>
    </template>
    <section class="modal-content w-100-p p-x-24 box-border">
    <!--      알림있을때-->
      <ul v-if="notificationList.length > 0">
<!--        true 읽은상태일때-->
        <li
          v-for="(noti, index) in notificationList"
          class="p-a-16 border-bottom-gray2-1 cursor-pointer flex justify-between items-start"
          :class="noti.read ? 'bg-white' : 'bg-primary1'"
        >
          <img src="@share/assets/images/logo-symbol-circle.svg" alt="">
          <div
            class="m-left-10 m-right-20"
            @click="handlePcGo(noti)"
          >
            <p class="text-body1 text-black m-bottom-12">
              {{ noti.message }}
            </p>
            <small class="text-caption2 text-t4">
              {{ convertDateFormat(noti.createdAt) }}
            </small>
          </div>
          <div
            class="position-relative"
            @click="handleToggleButton(index)"
          >
            <button class="reset-button">
              <IconKebabMenu color="#B8B8B8" width="24" height="24"/>
            </button>
            <div
              v-if="noti.opened"
              class="w-186 h-76 position-absolute right-0 z-10 shadow-default bg-white"
            >
              <button
                v-if="noti.read"
                class="p-left-20 reset-button w-100-p h-38 text-gray5 text-body1 text-left"
                @click="handleMarkUnread(noti.notificationId)"
              >
                Mark as Unread
              </button>
              <button
                v-else
                class="p-left-20 reset-button w-100-p h-38 text-gray5 text-body1 text-left"
                @click="handleMarkRead(noti.notificationId)"
              >
                Mark as Read
              </button>
              <button
                class="p-left-20 reset-button w-100-p h-38 text-body1 text-red text-left"
                @click="handleMarkDelete(noti.notificationId)"
              >
                Delete
              </button>
            </div>
          </div>
        </li>
      </ul>
      <!--      알림있을때-->
      <!--      알림없을때-->
      <p
        v-else
        class="text-body1-b text-center"
        style="padding-top: 19vh"
      >
        <IconNotification width="44" height="44"/><br/>
        No Notifications Yet
      </p>
      <!--      알림없을때-->
    </section>
  </LayoutModal>
</template>

<style scoped lang="scss">
</style>
