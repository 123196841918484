<script setup lang="ts">
import AIassistanttoggled from '@share/assets/images/AIassistanttoggled.png'
import AIassistant from '@share/assets/images/AIassistant.png'
import AIavatar from '@share/assets/images/AIavatar.png'
const aiOpen = ref(false)
const toggleAiOpen = () => {
  aiOpen.value = !aiOpen.value
}

function closeModal() {
  aiOpen.value = false
}
</script>

<template>
  <div class="AiAssistantFrame" :class="{ opened: aiOpen }">
    <div
      class="p-a-4"
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;

        gap: 0px !important;
        align-self: stretch;
      "
    >
      <IconClose style="width: 16px; height: 16px" class="close-modal" @click="closeModal" alt="Close" />
    </div>
    <div
      class="flex items-center m-top-8"
      style="
        color: var(--gray-black, #17171b);

        /* sub title1-b */
        font-family: Roboto;
        font-size: 20px;
        align-self: center;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 120% */
      "
    >
      <img src="@share/assets/images/AIavatar.png" class="m-right-12 items-center m-bottom-16" />
      The Pond AI Assistant is getting an update soon!
    </div>

    <div
      class="flex stretch m-bottom-16"
      style="
        color: var(--gray-gray-6, #434247);

        /* body1-r */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: 0.024px;
      "
    >
      With an advanced AI integrated with The Pond data, we will provide information for questions regarding college
      admissions and career planning, including:
    </div>

    <div
      class="flex flex-col items-start m-bottom-16"
      style="
        color: var(--gray-gray-6, #434247);

        /* body2-b */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        align-items: flex-start;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.035px;
      "
    >
      <p>
        • Admission Process
        <br />
        • Schools & Major Exploration
        <br />
        • Courses & Extracurriculars
        <br />
        • Essay Brainstorming
        <br />
        • Scholarship & FAFSA and Anything Else
      </p>
    </div>

    <div
      style="
        align-self: stretch;
        color: var(--gray-gray-6, #434247);

        /* body1-b */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
        letter-spacing: 0.024px;
      "
    >
      Now, all you need to do is ask what you should prepare!
    </div>

    <!-- <div style="height:500px; width:300px; background-color: peru;"></div> -->
  </div>

  <div class="ai-assistant-location">
    <img
      :src="aiOpen ? AIassistanttoggled : AIassistant"
      style="width: 78px"
      class="radius-200"
      @click="toggleAiOpen"
    />
  </div>
</template>

<style lang="scss" scoped>
.AiAssistantFrame {
  position: fixed;
  bottom: 110px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 1;
  flex-direction: column;
  border-radius: 16px;

  background: var(--primary-primary-1, #f5f7ff);
  /* shadow_style_center */
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  max-height: 0px;
  width: 328px;
  padding: 0px;
  transition: all 0.1s;
  z-index: 500;

  &.opened {
    width: 280px;
    max-height: 800px;
    padding: 16px 20px 32px 20px;
    align-items: right;
    z-index: 500;
  }
}

.ai-assistant-location {
  position: fixed;
  cursor: pointer;
  bottom: 24px;
  right: 24px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  z-index: 500;

  &:hover {
    opacity: 0.8;
  }
}

.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  gap: 0px !important; // This overrides the gap for the close-modal specifically
  align-self: stretch;
}
</style>