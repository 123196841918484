<script setup>
import { storeToRefs } from 'pinia'
import { useMenuStateStore } from '../../../packages/share/services/member/useMenuState'


const { menuStatus } = storeToRefs(useMenuStateStore())
const { getMenuStatus } = useMenuStateStore()

onMounted(async () => {
  await getMenuStatus()
})
</script>

<template>
  <LayoutGlobalTopHeader />

<!-- very back page (if you want to put a bg color for the whole page, set here) -->
  <main class="page scroll-invisible"
  style="background-color: #ffffff;">

    <LayoutLeftMenu />

<!-- content page (if you want to put a bg color just for the contents, set here) -->
    <div class="page-content scroll-invisible"
    style="margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    min-width: 1040px;
    background-color: #ffffff;"
    :class="{ 'page-content-full': !menuStatus  }">
      <PageMainAiAssistant /> 
      <slot />
    </div>
  </main>
</template>

<style scoped lang="scss">
.page {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  &-content {
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: calc(100% - 200px);
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    transition: width 0.3s ease-in-out;
    &.page-content-full {
      width: calc(100% - 60px);
    }
    @include respond-to(1024) {
      width: calc(100% - 18.33vw);
    }
  }
}
</style>
