<script setup>
import { useModal } from 'vue-final-modal'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import TermsAndConditions from '~/components/modal/auth/TermsAndConditions.vue'
import Notification from '~/components/modal/Notification.vue'
import useSubscriptionService from 'share/services/payment/useSubscriptionService'
import useNotificationService from 'share/services/notification/useNotificationService'
import useMemberAccountService from 'share/services/member/useMemberAccountService'
import { useMenuStateStore } from '../../../../packages/share/services/member/useMenuState'

// Merged code starts here

const { menuStatus } = storeToRefs(useMenuStateStore())
const { getMenuStatus } = useMenuStateStore()

const hoverDisplay = ref({
  home: false,
  mypond: false,
  roadmap: false,
  mentoring: false,
  career: false,
  college: false,
  extracurriculars: false,
  find: false,
  blog: false,
})

const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())
const { logout } = useMemberSessionStore()
const { fetchSubscriptionInfo, myPlanString, myPlanButtonString } = useSubscriptionService()
const { existUnread, fetchNotificationList } = useNotificationService()

const handleShowTermsModal = () => {
  const { open, destroy } = useModal({
    component: TermsAndConditions,
    attrs: {
      onConfirm: () => destroy(),
    },
  })
  open()
}

const handleShowNotificationModal = () => {
  const { open, destroy } = useModal({
    component: Notification,
    attrs: {
      onCancel: () => destroy(),
    },
  })
  open()
}

const mentoringHistoryUrlCheck = (currentUrl) => {
  console.log('currentUrl')
  console.log(currentUrl)

  // Check if the current URL starts with the specified pattern
  if (currentUrl.startsWith('/mentoring/info/booking/program')) {
    // Check if memberInfo is available and return mentoringHistory
    console.log('true')
    return true
  }
  // Return an empty array if the URL does not match or memberInfo is not available
  return false
}

const changeMentoringHistoryActiveLink = (url) => {
  if (currentUrl.startsWith('/mentoring/info/booking/program')) {
    return true
  }
  false
}

onMounted(async () => {
  if (isLoggedIn.value) {
    fetchSubscriptionInfo()
    fetchNotificationList()
  }
  await getMenuStatus()
})
</script>

<template>
  <nav
    class="left-menu"
    :class="{
      'justify-between': !isLoggedIn,
      folded: menuStatus === false,
    }"
    style="background-color: #ffffff"
  >
    <div>
      <ul class="holder-position">
        <li>
          <NuxtLink
            to="/"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700"
            @mouseover="hoverDisplay.home = true"
            @mouseleave="hoverDisplay.home = false"
          >
            <img src="@share/assets/images/menu/home.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">Home</span>
          </NuxtLink>

          <div
            v-if="!isLoggedIn && menuStatus === true"
            style="
              display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              align-self: stretch;
            "
          >
            <UiSeparator class="unfold-separator"></UiSeparator>
          </div>
          <div
            v-if="!isLoggedIn && menuStatus === false"
            style="
              display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              align-self: stretch;
            "
          >
            <UiSeparator class="fold-separator"></UiSeparator>
          </div>

          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.home">Home</div>
        </li>
        <li v-if="isLoggedIn">
          <NuxtLink
            to="/mypond"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            :active-class="
              $router.currentRoute.value.path === '/mypond/college/list'
                ? 'no-class'
                : 'bg-primary5 text-white text-700'
            "
            @mouseover="hoverDisplay.mypond = true"
            @mouseleave="hoverDisplay.mypond = false"
          >
            <img src="@share/assets/images/menu/my.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">My Pond</span>
          </NuxtLink>
          <div
            v-if="menuStatus === true"
            style="
              display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
              align-self: stretch;
            "
          >
            <UiSeparator class="unfold-separator"></UiSeparator>
          </div>
          <div
            v-else
            style="
              display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              align-self: stretch;
            "
          >
            <UiSeparator class="fold-separator"></UiSeparator>
          </div>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.mypond">My Pond</div>
        </li>

        <li v-if="isLoggedIn">
          <NuxtLink
            to="/roadmap"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700"
            @mouseover="hoverDisplay.roadmap = true"
            @mouseleave="hoverDisplay.roadmap = false"
          >
            <img src="@share/assets/images/menu/roadmap.svg" alt="" class="w-24 h-24 m-right-8 remove-margin" />
            <span class="hide-on-folded">Roadmap</span>
          </NuxtLink>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.roadmap">Roadmap</div>
        </li>
        <!-- <li>
          <NuxtLink
            to="/mentoring/find"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12 folded"
            :class="
              mentoringHistoryUrlCheck($router.currentRoute.value.path) ? 'bg-primary5 text-white text-700' : 'no-class'
            "
            @mouseover="hoverDisplay.find = true"
            @mouseleave="hoverDisplay.find = false"
            active-class="bg-primary5 text-white text-700"
          >
            <img src="@share/assets/images/menu/find.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded remove-margin" style="margin-right: 6px">Mentoring Program</span>
            <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.find">Mentoring Program</div>
            <img src="@share/assets/images/menu/newtag.svg" class="hide-on-folded" />
          </NuxtLink>
        </li> -->
        <!-- <li v-if="isLoggedIn">
          <NuxtLink
            to="/mentoring/info"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12 folded"
            active-class="bg-primary5 text-white text-700"
            :class="mentoringHistoryUrlCheck($router.currentRoute.value.path) ? 'unsetActive' : 'no-class'"
            @mouseover="hoverDisplay.mentoring = true"
            @mouseleave="hoverDisplay.mentoring = false"
          >
            <img src="@share/assets/images/menu/matching.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded" style="margin-right: 9px">Mentoring History</span>
            <img src="@share/assets/images/menu/newtag.svg" class="hide-on-folded" />
          </NuxtLink>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.mentoring">Mentoring History</div>
        </li> -->
        <!-- <div
          v-if="menuStatus === true"
          style="
            display: flex;
            padding: 8px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
          "
        >
          <UiSeparator class="unfold-separator"></UiSeparator>
        </div> -->
        <!-- <div
          v-else
          style="
            display: flex;
            padding: 8px 0px;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            align-self: stretch;
          "
        >
          <UiSeparator class="fold-separator"></UiSeparator>
        </div> -->
        <li>
          <NuxtLink
            to="/college/find"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700"
            @mouseover="hoverDisplay.college = true"
            @mouseleave="hoverDisplay.college = false"
          >
            <img src="@share/assets/images/menu/college.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">College Finder</span>
          </NuxtLink>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.college">College Finder</div>
        </li>
        <li>
          <NuxtLink
            to="/extracurriculars"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700"
            @mouseover="hoverDisplay.extracurriculars = true"
            @mouseleave="hoverDisplay.extracurriculars = false"
          >
            <img src="@share/assets/images/menu/activity.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">Activity Search</span>
          </NuxtLink>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.extracurriculars">
            Activity Search
          </div>
        </li>

        <li>
          <NuxtLink
            to="/career/search"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            active-class="bg-primary5 text-white text-700"
            @mouseover="hoverDisplay.career = true"
            @mouseleave="hoverDisplay.career = false"
          >
            <img src="@share/assets/images/menu/career.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">Career Finder</span>
          </NuxtLink>
          <div
            v-if="menuStatus === true"
            style="
              display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
            "
          >
            <UiSeparator class="unfold-separator"></UiSeparator>
          </div>
          <div
            v-else
            style="
              display: flex;
              padding: 8px 0px;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              align-self: stretch;
            "
          >
            <UiSeparator class="fold-separator"></UiSeparator>
          </div>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.career">Career Finder</div>
        </li>
        <li>
          <NuxtLink
            to="https://www.blog.diveintothepond.com/"
            target="_blank"
            class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12"
            @mouseover="hoverDisplay.blog = true"
            @mouseleave="hoverDisplay.blog = false"
          >
            <img src="@share/assets/images/menu/blog.svg" alt="" class="w-24 h-24 m-right-8" />
            <span class="hide-on-folded">Blog</span>
          </NuxtLink>
          <div class="hover-icon-menu" v-if="menuStatus === false && hoverDisplay.blog">Blog</div>
        </li>
      </ul>
      <!-- <UiLabel class="payment hide-on-folded m-top-32">Coming soon</UiLabel>
      <ul class="m-bottom-32 m-top-4 hide-on-folded">
        <li>
          <div class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12">
            <img src="@share/assets/images/menu/courses.svg" alt="" class="w-24 h-24 m-right-8" />
            Courses
          </div>
        </li>
        <li>
          <div class="flex h-44 text-gray6 text-body2 radius-12 m-bottom-4 items-center p-x-12">
            <img src="@share/assets/images/menu/feedback.svg" alt="" class="w-24 h-24 m-right-8" />
            Essay Feedback
          </div>
        </li>
      </ul> -->
    </div>

    <!-- <div class="hide-on-folded">
      <div class="flex justify-center m-top-40">
        <button class="reset-button text-underline text-black text-caption2 m-right-17" @click="handleShowTermsModal">
          Terms
        </button>
        <a
          href="https://www.iubenda.com/privacy-policy/18448319"
          class="text-underline text-black text-caption2 m-right-17"
          target="_blank"
        >
          Privacy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/18448319/cookie-policy"
          class="text-underline text-black text-caption2"
          target="_blank"
        >
          Cookie
        </a>
      </div>
      <p class="m-y-16 text-center">
        <a href="mailto:customerservice@letitu.io" class="text-underline text-black text-caption2" target="_blank">
          Contact us
        </a>
      </p>
      <p class="text-caption2 text-gray4 text-center">©Letitu Corp. All rights reserved</p>
    </div> -->
  </nav>
</template>

<style scoped lang="scss">
.left-menu {
  box-sizing: border-box;
  background: $primary1;
  width: 236px;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 40px);
  padding: 12px 12px 40px 8px;
  display: inline-flex;
  align-items: flex-start;
  gap: 32px;
  flex-direction: column;
  flex: none;
  bottom: 0;
  top: 0;
  border-right: 1px solid $t2;
  // overflow-y: auto;
  transition: width 0.2s ease-in-out;

  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  &.folded {
    width: 68px;
    display: inline-flex;
    height: 976px;
    padding: 12px 12px 40px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    .holder-position {
      li > a {
        padding-right: 4px !important;
      }
    }

    .hide-on-folded {
      display: none;

      .remove-margin {
        margin-right: 0 !important;
      }
    }
  }

  @include respond-to(880, 'max-height') {
    max-height: 700px;
    overflow-y: auto;
  }

  @include respond-to(1024) {
    width: 18.33vw;
    padding: 1.66vw 1.66vw 2.77vw;
  }

  .logo {
    width: 110px;
    height: 25px;
    background-repeat: no-repeat;
    background-image: url('@share/assets/images/logo.svg');
    background-size: contain;
  }
}

.hover-icon-menu {
  position: absolute;
  top: 13px;
  left: calc(100% + 4px);
  text-align: left;
  background-color: #ffe499;
  border-radius: 8px;
  @include set-text(14px, 500, 16px);
  color: black;
  letter-spacing: 0.033px;
  display: block;
  padding: 2px 4px;
  z-index: 101;
  white-space: nowrap;
}

.holder-position {
  position: absolute;

  > li {
    position: relative;
    width: inherit;
  }
}

.unsetActive {
  background-color: transparent !important;
  color: $gray6 !important;
  font-weight: 400 !important;
}

.unfold-separator {
  width: 216px;
  height: 1px;
  background: #d0daff;
}

.fold-separator {
  width: 48px;
  height: 1px;
  background: #d0daff;
}
</style>
