<script setup>
import { useModal } from 'vue-final-modal'
import { useMemberSessionStore } from 'share/stores/memberSessionStore'
import { storeToRefs } from 'pinia'
import useSubscriptionService from 'share/services/payment/useSubscriptionService'
import useNotificationService from 'share/services/notification/useNotificationService'
import Notification from '~/components/modal/Notification.vue'
import Profile from '~/components/modal/Profile.vue'
import { useMenuStateStore } from '../../../../packages/share/services/member/useMenuState'

const { menuStatus } = storeToRefs(useMenuStateStore())
const { getMenuStatus, setMenuStatus } = useMenuStateStore()

const { isLoggedIn, memberInfo } = storeToRefs(useMemberSessionStore())

const { fetchSubscriptionInfo, myPlanString, myPlanButtonString, subscriptionInfo } = useSubscriptionService()
const { existUnread, fetchNotificationList } = useNotificationService()

onMounted(async () => {
  if (isLoggedIn.value) {
    fetchSubscriptionInfo()
    fetchNotificationList()
    await getMenuStatus()
  }
})

const handleShowNotificationModal = () => {
  const { open, destroy } = useModal({
    component: Notification,
    attrs: {
      onCancel: () => destroy(),
    },
  })
  open()
}

const openMyProfile = () => {
  console.log('openMyProfile')

  const { open, destroy } = useModal({
    component: Profile,
    attrs: {
      modelwidth: '360px',
      onClose: async () => {
        console.log('closed')

        destroy()
      },
      onConfirm: () => {
        destroy()
      },
    },
  })
  open()
}
</script>

<template>
  <div class="sticky-header border-box w-100-p">
    <div class="flex" style="gap: 16px">
      <IconHamburger style="cursor: pointer" @click="setMenuStatus(!menuStatus)" />
      <div class="flex items-center gap-8">
      <div class="logo cursor-pointer" @click="navigateTo('/')"></div>
      <mark
          class="flex radius-200 border-a-primary3-1 text-caption2 text-primary4 inline-block items-center text-center"
          style="padding: 0px 6px;">
          Beta
        </mark>
      </div>
    </div>

    <div class="flex items-center" v-if="isLoggedIn">
      <article
        class="flex items-center"
        style="gap: 12px"
        v-if="['trialing', 'active'].includes(subscriptionInfo.status) === false"
      >
        <UiButton
          class="m-x-12 m-y-8 w-100-p medium"
          style="white-space: pre"
          @click="navigateTo('/purchase/subscription')"
        >
          Upgrade
        </UiButton>
        <button
          class="flex-none w-40 h-40 radius-200 bg-white position-relative cursor-pointer"
          @click="handleShowNotificationModal"
        >
          <mark
            v-if="existUnread"
            class="w-6 h-6 inline-block bg-red radius-200 position-absolute top-7 right-8"
          ></mark>
          <IconAlert width="24" height="24" color="#rgba(67, 66, 71, 1)" />
        </button>
        <div class="w-32 h-32 radius-200 cursor-pointer" @click="openMyProfile" style="flex: none">
          <img
            :src="memberInfo.imageUrl ? memberInfo.imageUrl : '/images/avatar.svg'"
            alt=""
            class="fit-cover w-100-p h-100-p radius-200"
          />
        </div>
      </article>
      <article class="flex items-center" style="gap: 12px" v-else>
        <UiLabel class="small border blue cursor-pointer" @click="navigateTo('/purchase/subscription')">
          {{ myPlanString }}
        </UiLabel>
        <button
          class="flex-none w-40 h-40 radius-200 bg-white position-relative cursor-pointer"
          @click="handleShowNotificationModal"
        >
          <mark
            v-if="existUnread"
            class="w-6 h-6 inline-block bg-red radius-200 position-absolute top-7 right-8"
          ></mark>
          <IconAlert width="24" height="24" color="#rgba(67, 66, 71, 1)" />
        </button>
        <div class="w-32 h-32 radius-200 cursor-pointer" @click="openMyProfile" style="flex: none">
          <img
            :src="memberInfo.imageUrl ? memberInfo.imageUrl : '/images/avatar.svg'"
            alt=""
            class="fit-cover w-100-p h-100-p radius-200"
          />
        </div>
      </article>
    </div>
    <div class="flex items-center" style="gap: 12px" v-else>
      <UiButton class="u-button light-primary medium w-100-p" @click="navigateTo('/auth/sign-in')">Sign in</UiButton>
      <UiButton class="m-y-16 w-80-p medium" style="white-space: pre" @click="navigateTo('/auth/sign-up')">
        Sign up
      </UiButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sticky-header {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 8px 20px;

  background-color: #fff; /* Adjust as needed */
  border-bottom: 1px solid #eaeaea;
  z-index: 5;
}

.logo {
  width: 110px;
  height: 25px;
  background-repeat: no-repeat;
  background-image: url('@share/assets/images/logo.svg');
  background-size: contain;
}
</style>
